﻿module Bfs.Web.Kundenportal.WebParts.Notifications.State

open Bfs.Web.Data.Service.Contracts.Kundenportal
open Bfs.Web.Data.Service.Contracts.Kundenportal.Account
open Bfs.Web.Data.Service.Contracts.Kundenportal.Notifications
open Bfs.Web.Kundenportal.JsInterop
open Bfs.Web.Kundenportal.PWA.NotificationBindings
open Bfs.Web.Kundenportal.WebParts.Notifications.Types
open Http
open Types

open Elmish

module Http =
    let userDetails () = fetchAs<UserInfo> "/api/account/details"
    let updateNotifications (data) = postRecord3<UserNotifications> "/api/account/notifications" data
    let validateEndpoint (endpoint: string) =
        postRecord2<EndpointIdentifier, bool> "/api/notification/status" { Endpoint = endpoint }

module State =
    let init page (user: UserSession option) lastModel =
        let name =
            user
            |> Option.map (fun u -> u.Name)
            |> Option.defaultValue ""

        let email =
            user
            |> Option.map (fun u -> u.Email)
            |> Option.defaultValue ""

        let preisInfosAnzeigen =
            user.Value.Roles
            |> List.contains Roles.KundeMitPreisInfosAnzeigen

        let model = Model.Init name email preisInfosAnzeigen

        model,
        Cmd.batch [
            Cmd.ofMsg LoadUserInfo
            Cmd.ofMsg LoadPushSubscription
        ]

    let update msg model : Model * Cmd<Msg> =

        match msg with
        | PostImPortalChanged b -> ({ model with PostImPortal = b }, Cmd.none)
        | PreisInfosChanged b -> ({ model with PreisInfos = b }, Cmd.none)
        | BelegeingangChanged b -> ({ model with Belegeingang = b }, Cmd.none)
        | BelegpruefungAbgeschlossenChanged b ->
            ({
                model with
                    BelegpruefungAbgeschlossen = b
             },
             Cmd.none)
        | AuszahlungGetaetigtChanged b -> ({ model with AuszahlungGetaetigt = b }, Cmd.none)
        | TicketAntwortChanged b -> ({ model with TicketAntwort = b }, Cmd.none)

        | SubmitNotifications ->
            let data = {
                NotificationForPostImPortal = model.PostImPortal
                NotificationForPreisInfos = model.PreisInfos
                NotificationForBelegeingang = model.Belegeingang
                NotificationForBelegpruefungAbgeschlossen = model.BelegpruefungAbgeschlossen
                NotificationForAuszahlungGetaetigt = model.AuszahlungGetaetigt
                NotificationForTicketAntwort = model.TicketAntwort
            }

            let cmd =
                NotificationsRequestDone
                |> request Http.updateNotifications data
            ({ model with Sending = true }, cmd)

        | NotificationsRequestDone(Ok "") ->
            let cmd =
                GlobalMessageBox.Success
                    "Die Einstellungen wurden gespeichert. Wir werden Sie nun wie gewünscht per E-Mail informieren."
                |> ShowMessageBox
                |> GlobalMsg
                |> Cmd.ofMsg
            ({ model with Sending = false }, cmd)
        | NotificationsRequestDone(Ok error) ->
            ({ model with Sending = false }, error |> errorCmd2 GlobalMsg "Speichern")
        | NotificationsRequestDone(Error e) -> ({ model with Sending = false }, e |> errorCmd GlobalMsg "Speichern")

        | LoadUserInfo -> model, (UserInfoLoaded |> (request Http.userDetails ()))
        | UserInfoLoaded x ->
            let model =
                match x with
                | Ok x -> {
                    model with
                        PostImPortal = x.Notifications.NotificationForPostImPortal
                        PreisInfos = x.Notifications.NotificationForPreisInfos
                        Belegeingang = x.Notifications.NotificationForBelegeingang
                        BelegpruefungAbgeschlossen = x.Notifications.NotificationForBelegpruefungAbgeschlossen
                        AuszahlungGetaetigt = x.Notifications.NotificationForAuszahlungGetaetigt
                        TicketAntwort = x.Notifications.NotificationForTicketAntwort
                        Sending = false
                  }
                | _ -> model

            ({ model with UserInfo = Loaded x }, Cmd.none)

        | LoadPushSubscription ->
            match notificationBindings.notificationPermission () with
            | x when x = NotificationPermissionDenied ->
                {
                    model with
                        PushState = Remote.Body PushState.AppDenied
                },
                Cmd.none
            | x when x = NotificationPermissionUnset ->
                {
                    model with
                        PushState = Remote.Body PushState.AppUndecided
                },
                Cmd.none
            | _ ->
                let getSubscription _ =
                    notificationBindings.serviceWorkerRegistration
                    |> Promise.bind notificationBindings.getSubscription

                {
                    model with
                        PushState = Remote.Loading
                },
                Cmd.OfPromise.either
                    getSubscription
                    ()
                    (Ok >> LoadPushSubscriptionCompleted)
                    (Error >> LoadPushSubscriptionCompleted)

        | LoadPushSubscriptionCompleted(Ok subscription) ->
            match subscription with
            | null ->
                {
                    model with
                        PushState = Remote.Body PushState.Disabled
                },
                Cmd.none
            | sub ->
                let endpoint = (sub |> notificationBindings.mapSubscription).Endpoint
                model,
                (ValidatePushEndpointCompleted
                 |> (request Http.validateEndpoint endpoint))
        | LoadPushSubscriptionCompleted(Error ex) ->
            {
                model with
                    PushState = Remote.LoadError "Push-Nachrichten können zur Zeit leider nicht aktiviert werden"
            },
            Cmd.none

        | ValidatePushEndpointCompleted(Ok isValid) ->
            match isValid with
            | true ->
                {
                    model with
                        PushState = Remote.Body PushState.Enabled
                },
                Cmd.none
            | false ->
                {
                    model with
                        PushState = Remote.Body PushState.Disabled
                },
                Cmd.none

        | ValidatePushEndpointCompleted(Error ex) ->
            {
                model with
                    PushState =
                        Remote.LoadError "Der Status Ihrer Push-Nachrichten kann zur Zeit leider nicht abgerufen werden"
            },
            Cmd.none

        | ChangePushSubscription isActive ->
            { model with SendingPushState = true },
            Cmd.OfPromise.either
                (match isActive with
                 | true -> NotificationHelper.SubscribeToWebPush
                 | false -> NotificationHelper.UnsubscribeFromWebPush)
                ()
                (Ok >> ChangePushSubscriptionCompleted)
                (Error >> ChangePushSubscriptionCompleted)

        | ChangePushSubscriptionCompleted(Ok success) ->
            {
                model with
                    SendingPushState = false
                    PushState = Remote.Loading
            },
            Cmd.batch [
                LoadPushSubscription |> Cmd.ofMsg
                if not success then
                    GlobalMessageBox.Error
                        "Die Einstellungen konnten leider nicht gespeichert werden. Bitte versuchen Sie es später erneut."
                    |> ShowMessageBox
                    |> GlobalMsg
                    |> Cmd.ofMsg
            ]

        | ChangePushSubscriptionCompleted(Error ex) ->
            {
                model with
                    SendingPushState = false
                    PushState = Remote.Loading
            },
            Cmd.batch [
                LoadPushSubscription |> Cmd.ofMsg
                GlobalMessageBox.Error "Auf Ihrem Gerät konnten leider keine Pushnachrichten aktiviert werden."
                |> ShowMessageBox
                |> GlobalMsg
                |> Cmd.ofMsg
            ]

        | GlobalMsg _ -> (model, Cmd.none) // diese Message wird gar nicht hier behandelt, sondern im Dispatcher!
