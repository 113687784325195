﻿namespace Bfs.Web.Kundenportal

open Fable.Core
open Browser
open Fable.React
open Types

module JsInterop =

    [<RequireQualifiedAccess>]
    module JS =

        [<Emit("$0.scrollIntoView({behavior: \"smooth\"})")>]
        let scrollIntoView (x: Element) : unit = jsNative

module HtmlInteropt =
    let PlaysInline playsinline =
        if playsinline then
            Props.HTMLAttr.Custom("playsinline", "")
        else
            Props.HTMLAttr.Custom("", "")
